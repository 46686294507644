module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"discovr","short_name":"discovr","start_url":"/app","background_color":"#1273f8","theme_color":"#1273f8","display":"standalone","icon":"src/images/favicons/apple-icon.png","icons":[{"src":"/favicons/appstore.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/appstore.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/appstore.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/appstore.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/appstore.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/appstore.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/appstore.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/appstore.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0e46d485fd0817c45c2eb963e8679895"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
